import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"

export const ArticleMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  ${breakpointsMedia(breakpoints.tabletWide)} {
    margin-bottom: 0;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .meta {
    min-height: 42px;
    display: flex;
    align-items: center;

    &--icon {
      color: var(--color-primary);
      font-size: 15px;
      line-height: 15px;
      margin-right: 10px;
    }

    &--data {
      color: #b8b8b8;
      font-size: 15px;
      line-height: 35px;
      margin-right: 19px;
      transition: color 0.15s;
    }
  }
`
