import React from "react"

import { HeroStyleConstrained } from "@styles/organisms/Hero"
import styled from "styled-components"
import {
  breakpoints,
  breakpointsMedia,
} from "@styles/variables/Breakpoint"
import HeroHead from "./HeroHead"

const BlogArticleHero = styled(HeroStyleConstrained)`
  min-height: 380px;

  .container {
    align-items: flex-start;
    padding-top: 127px;

    .inner {
      max-width: 814px;

      .title {
        ${breakpointsMedia(breakpoints.tablet)} {
          font-size: 48px;
          line-height: 62px;
        }
      }
    }
  }

  .hero-head {
    .breadcrumbs {
      ${breakpointsMedia(breakpoints.tablet, "max")} {
        display: none !important;
      }
    }
  }
`

export default function BlogSingleHero({ title, uri }) {
  const breadcrumbs = [
    {
      url: "/blog/",
      title: "Blog",
      isHighlight: true,
      hasArrow: true,
    },
    {
      title: title,
    },
  ]

  return (
    <>
      <BlogArticleHero>
        <div className="container">
          <HeroHead breadcrumbs={breadcrumbs} uri={uri} />
          <div className="inner" />
        </div>
      </BlogArticleHero>
    </>
  )
}
