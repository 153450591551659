import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

const Author = props => {
  return (
    <Link className="author-wrap meta" to={props.uri}>
      <div className="profile-image default">
        {props.profileImg && <GatsbyImage image={props.profileImg} />}
      </div>
      <div className="meta--data name">{props.name}</div>
    </Link>
  );
}

Author.propTypes = {
  name: PropTypes.string,
}

Author.defaultProps = {
  profileImg: false,
}

export default Author
