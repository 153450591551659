import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "styled-components"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { breakpoints, breakpointsMedia } from "@styles/variables/Breakpoint"
import { TermPillLink } from "@styles/atoms/Pills"

import Seo from "@components/Seo"
import BlogSingleHero from "@components/sections/heros/BlogSingleHero"
import Author from "@components/ui/author"
import SocialLinks from "@components/ui/social/SocialLinks"

import FruitLogo from "../../static/svgs/logo-fruit.svg"
import { ArticleMeta } from "@components/parts/ArticleMeta"
import Heading from "@components/ui/Heading"
import Spacer from "@components/ui/Spacer"

export const BlogPostStyle = styled.section`
  padding-bottom: 60px;
  margin-top: -247px;

  .blog-title {
    text-align: center;
    margin: 0;
    margin-bottom: 70px;
  }

  .featured-image {
    img {
      width: 100%;
    }
  }

  .entry-content {
    width: 900px;
    background-color: #ffffff;
    box-shadow: 0 14px 74px 7px rgba(0, 0, 0, 0.04);
    max-width: 100%;
    margin: auto;
    padding-bottom: 35px;

    .entry-inner {
      max-width: 760px;
      margin: auto;
      padding: 30px;
    }

    .entry {
      margin-bottom: 40px;

      p {
        margin: 0.6em 0 1em 0;
      }

      i:not([class*="fa"]),
      em {
        font-style: italic;
      }

      ol {
        list-style-type: decimal;
        margin-block-start: 1em;
        margin-block-end: 1em;
        padding-inline-start: 40px;
        font-weight: 300;
      }
    }

    ul.share-links {
      list-style: inside;
      font-weight: 300;
      margin: 0;
    }

    .social-share-wrap {
      display: flex;
      align-items: center;

      .label {
        margin-right: 18px;
      }
    }
  }

  .article-footer,
  .article-header {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    ${breakpointsMedia(breakpoints.tabletWide)} {
      align-items: flex-start;
      flex-direction: row;
      justify-content: space-between;
    }

    a.author-wrap {
      display: flex;
      align-items: center;
      color: var(--color-text);
      text-decoration: none;
      max-width: 225px;

      .profile-image {
        height: 35px;
        width: 35px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 100%;
        margin-right: 15px;
        overflow: hidden;

        &.default {
          background-size: 85%;
          background-image: url(${FruitLogo});
        }
      }

      .name {
        color: var(--color-text);
        font-size: 17px;
        line-height: 44px;
        font-weight: bold;
      }

      &.meta {
        .name {
          font-weight: 300;
          font-size: 17px;
          line-height: 18px;
          color: #b8b8b8;
        }
      }
    }
  }

  .article-header {
    padding-bottom: 36px;

    .author-wrap {
      ${breakpointsMedia(breakpoints.tabletWide, "max")} {
        width: 100%;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .article-footer {
    .author-wrap {
      margin-bottom: 12px;

      ${breakpointsMedia(breakpoints.tabletWide)} {
        margin-bottom: 0;
      }
    }
  }

  .article-tags,
  .article-categories {
    display: flex;
    flex-wrap: wrap;
  }

  .article-categories {
    max-width: 50%;
    justify-content: flex-end;

    .term {
      margin-left: 10px;
      color: white;
    }
  }
`

//TODO:convert in to styled components
export default function BlogPost({ data }) {
  const post = data.allWpPost.nodes[0]

  const opengraphImageSrc =
    post.featuredImage?.node.localFile &&
    getImage(post.featuredImage?.node.localFile)

  const author = (
    <Author
      name={post.author.node.name}
      uri={post.author.node.uri}
      profileImg={
        post.author.node.profileFields?.profileImage?.localFile?.childImageSharp
          ?.gatsbyImageData
      }
    />
  )

  const category = post.categories?.nodes[0]
  return (
    <>
      <Seo {...post.seo} />
      <Helmet
        bodyAttributes={{
          class: "single-post short",
        }}
      />

      <BlogSingleHero {...post} />

      <div className="container">
        <BlogPostStyle
          className="blog row"
          id="main"
          itemScope=""
          itemType="http://schema.org/Blog"
        >
          <div
            className="article"
            itemScope=""
            itemProp="blogPost"
            itemType="http://schema.org/BlogPosting"
          >
            <div style={{ textAlign: "center" }}>
              <Heading>{post.title}</Heading>
            </div>
            <Spacer y="sm" />

            <div className="entry-content">
              {opengraphImageSrc && (
                <div className="featured-image">
                  <GatsbyImage image={opengraphImageSrc} />
                </div>
              )}
              <div className="entry-inner">
                <div className="article-header">
                  <ArticleMeta className="article-meta">
                    {author}
                    <div className="meta">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="calendar"
                        className="svg-inline--fa fa-calendar fa-w-14 meta--icon"
                        role="img"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M400 64h-48V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H128V12c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.822 0 16 7.178 16 16v48H32v-48c0-8.822 7.178-16 16-16zm352 384H48c-8.822 0-16-7.178-16-16V192h384v272c0 8.822-7.178 16-16 16z"
                        />
                      </svg>
                      <span className="meta--data">{post.date}</span>
                    </div>
                    <div className="meta">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fal"
                        data-icon="clock"
                        className="svg-inline--fa fa-clock fa-w-16 meta--icon"
                        role="img"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216zm-148.9 88.3l-81.2-59c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h14c6.6 0 12 5.4 12 12v146.3l70.5 51.3c5.4 3.9 6.5 11.4 2.6 16.8l-8.2 11.3c-3.9 5.3-11.4 6.5-16.8 2.6z"
                        />
                      </svg>
                      <span className="meta--data">
                        {post.timeToRead + " to read"}
                      </span>
                    </div>
                  </ArticleMeta>

                  {category && (
                    <div className="article-categories">
                      <TermPillLink to={category.uri} className="term">
                        {category.name}
                      </TermPillLink>
                    </div>
                  )}
                </div>
                <div className="entry">
                  <div itemProp="text">
                    <div dangerouslySetInnerHTML={{ __html: post.content }} />
                  </div>
                </div>
                <div className="article-footer">
                  {author}
                  <div className="social-share-wrap">
                    <strong className="label">Share:</strong>
                    <SocialLinks uri={post.uri} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BlogPostStyle>
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        id
        uri
        title
        date(formatString: "D/M/Y")
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  height: 600
                  quality: 95
                  transformOptions: { cropFocus: NORTH }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
        timeToRead
        content
        link
        author {
          node {
            firstName
            lastName
            name
            uri
            profileFields {
              profileImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 35
                      layout: FIXED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
        seo {
          canonical
          cornerstone
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 900
                  height: 600
                  quality: 80
                  layout: FIXED
                )
              }
            }
          }
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          schema {
            raw
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
      }
    }
  }
`
